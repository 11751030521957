<template>
  <div class="w-100">
    <v-card-text class="justify-space-between pt-0">
      <v-container>
        <v-row class="d-flex elevation-0 mt-3 pa-2">
          <v-col sm="5" class="black--text text-body-2" cols="12" lg="8" xl="8">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td><strong>Artikelnummer:</strong></td>
                    <td>
                      {{ baseInformations.key.artNr }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Kategorie:</strong></td>
                    <td>
                      {{
                        baseInformations.articleCategory == null ||
                        baseInformations.articleCategory.description == ''
                          ? '---'
                          : baseInformations.articleCategory.description
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Typ:</strong></td>
                    <td>
                      {{
                        baseInformations.articleType == null ||
                        baseInformations.articleType.key.tpCode == ''
                          ? '---'
                          : baseInformations.articleType.key.tpCode
                      }}
                      {{
                        baseInformations.articleType == null ||
                        baseInformations.articleType.description == ''
                          ? '---'
                          : baseInformations.articleType.description
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Gruppe:</strong></td>
                    <td>
                      {{
                        baseInformations.articleGroup == null ||
                        baseInformations.articleGroup.description == ''
                          ? '---'
                          : baseInformations.articleGroup.key.lfdnr +
                            ' ' +
                            baseInformations.articleGroup.description
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>StatistikCode:</strong></td>
                    <td>
                      {{
                        baseInformations.articleStatCode == null ||
                        baseInformations.articleStatCode == ''
                          ? '---'
                          : baseInformations.articleStatCode
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col md="7" class="d-flex justify-end" cols="12" lg="4" xl="4">
            <v-spacer></v-spacer>
            <!--            <v-img-->
            <!--              v-if="articleImage != null"-->
            <!--              height="200px"-->
            <!--              contain-->
            <!--              v-bind:src="articleImage"-->
            <!--              class="d-flex justify-end"-->
            <!--            />-->
            <v-carousel
              v-if="articleImages.length > 0"
              height="230px"
              contain
              show-arrows-on-hover
              hide-delimiter-background
              light
              delimiter-icon="mdi-minus"
            >
              <v-carousel-item
                v-for="(item, i) in articleImages"
                :key="i"
                contain
              >
                <v-img
                  v-if="item.sm == null"
                  height="180px"
                  contain
                  v-bind:src="item.image"
                  class="mr-0"
                />
                <v-img
                  v-else
                  height="160px"
                  contain
                  v-bind:src="item.image"
                  class="mr-0"
                />
                <div
                  v-if="item.sm != null"
                  class="mt-2"
                  style="width:100%; text-align: center"
                >
                  {{ item.sm }}
                </div>
              </v-carousel-item>
            </v-carousel>
            <v-img
              v-else
              height="200px"
              contain
              src="../../assets/img/article-no-image.png"
              class="mr-0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center flex-wrap">
            <v-checkbox
              v-model="checkboxes.artLocked"
              color="primary"
              disabled
              label="gesperrt"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artSet"
              color="primary"
              label="Set"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artKrankenKasse"
              color="primary"
              label="Krankenkassenposition"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artInventoryManagement"
              color="primary"
              label="Bestandsführung"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artSaison"
              color="primary"
              label="Saison"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artObject"
              color="primary"
              label="Objektsverwaltung"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artQuantityID"
              color="primary"
              label="MengenID"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artProductionArticle"
              color="primary"
              label="Produktionsartikel"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artCommission"
              color="primary"
              label="Provision"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
            <v-checkbox
              v-model="checkboxes.artCharge"
              color="primary"
              label="Chargen"
              disabled
              class="pl-2 pr-2 float-left"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-3 d-flex flex-wrap">
          <v-col cols="12" class="d-flex flex-wrap">
            <v-text-field
              label="Lagereinheit"
              class="pa-2"
              outlined
              disabled
              v-model="InventoryManagement.storageUnit"
            >
            </v-text-field>
            <v-text-field
              label="Objekttype"
              class="pa-2"
              outlined
              disabled
              v-model="InventoryManagement.objectType"
            >
            </v-text-field>
            <v-text-field
              label="Objekttypengruppe"
              class="pa-2"
              outlined
              disabled
              v-model="InventoryManagement.objectTypeGroup"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <br />
        <v-row>
          <v-col cols="12">
            <v-tabs center-active centered v-model="tab">
              <v-tab key="Informationen"> Informationen </v-tab>
              <v-tab key="Sachmerkmale">
                Sachmerkmale
              </v-tab>
              <v-tab key="Verkaufspreise">
                Verkaufspreise
              </v-tab>
              <v-tab key="Einkaufspreise">
                Einkaufspreise
              </v-tab>
              <v-tab key="Artikelbewegungen">
                Artikelbewegungen
              </v-tab>
              <v-tab key="BeauftragtBestellt">
                Beauftragt/Bestellt
              </v-tab>
              <v-tab key="Lagerbestände">
                Lagerbestände
              </v-tab>
              <v-tab key="offAngebote">
                off. Angebote
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" v-if="this.visibleTabs.length >= 0">
              <v-tab-item key="Informationen">
                <v-row class="d-flex mt-2">
                  <v-col cols="6" class="text-h6">
                    Bemerkung:
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="baseInformations.comment"
                      :options="quillConfig"
                      style="height: 400px"
                      disabled
                    />
                  </v-col>
                  <v-col cols="6" class="text-h6">
                    Vermerk:
                    <quill-editor
                      ref="myQuillEditor"
                      v-model="baseInformations.note"
                      :options="quillConfig"
                      style="height: 400px"
                      disabled
                    />
                  </v-col>
                </v-row>
                <div class="mt-2"></div>
              </v-tab-item>
              <v-tab-item key="Sachmerkmale">
                <ArticleCharacteristics />
              </v-tab-item>
              <v-tab-item key="Verkaufspreise">
                <ArticleSalePrices />
              </v-tab-item>
              <v-tab-item key="Einkaufspreise">
                <ArticlePurchasePrice />
              </v-tab-item>
              <v-tab-item key="Artikelbewegungen">
                <ArticleMovements />
              </v-tab-item>
              <v-tab-item key="BeauftragtBestellt">
                <ArticleCommissionedOrdered />
              </v-tab-item>
              <v-tab-item key="Lagerbestände">
                <ArticleStock />
              </v-tab-item>
              <v-tab-item key="offAngebote">
                <ArticleOpen />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import ArticleCharacteristics from './articleDetailsTabs/ArticleCharacteristics.vue'
import ArticleSalePrices from './articleDetailsTabs/ArticleSalePrices.vue'
import ArticlePurchasePrice from './articleDetailsTabs/ArticlePurchasePrice.vue'
import ArticleMovements from './articleDetailsTabs/ArticleMovements.vue'
import ArticleCommissionedOrdered from './articleDetailsTabs/ArticleCommissionedOrdered.vue'
import ArticleStock from './articleDetailsTabs/ArticleStock.vue'
import ArticleOpen from './articleDetailsTabs/ArticleOpen.vue'
import ErrorDialog from '@/components/core/ErrorDialog.vue'

import { mapState } from 'vuex'

export default {
  components: {
    ArticleCharacteristics,
    ArticlePurchasePrice,
    ArticleSalePrices,
    ArticleMovements,
    ArticleCommissionedOrdered,
    ArticleStock,
    ArticleOpen,
    quillEditor,
    ErrorDialog
  },
  props: {
    articleNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      tabs: [
        'Informationen',
        'Sachmerkmale',
        'Verkaufspreise',
        'Einkaufspreise',
        'Artikelbewegungen',
        'BeauftragtBestellt',
        'Lagerbestände',
        'off. Angebote'
      ],
      quillConfig: {
        modules: {
          toolbar: false
        },
        placeholder: '',
        readOnly: true,
        theme: 'snow'
      },
      checkboxitems: [
        // Hardcoded. Insert from Database later if needed!
        { id: 1, text: 'gesperrt', value: false },
        { id: 2, text: 'Set', value: false },
        { id: 3, text: 'Krankenkassenposition', value: false },
        { id: 4, text: 'Bestandsführung', value: false },
        { id: 5, text: 'Saison', value: false },
        { id: 6, text: 'Objektsverwaltung', value: false },
        { id: 7, text: 'Mengen-ID', value: false },
        { id: 8, text: 'Produktionsartikel', value: false },
        { id: 9, text: 'Provision', value: false },
        { id: 10, text: 'Chargen', value: false }
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    ...mapState({
      baseInformations: state => state.articleDetails.baseInformation,
      articleImages: state => state.articleDetails.articleImages,
      checkboxes: state => state.articleDetails.checkboxes,
      InventoryManagement: state => state.articleDetails.InventoryManagement,
      visibleTabs: state => state.articlePermissions.visibleTabs
    })
  },
  methods: {
    init() {
      this.loadArticleImages(),
        this.loadCheckboxes(),
        this.loadInventoryManagement()
    },
    loadArticleImages() {
      try {
        this.$store.dispatch(
          'GET_ARTICLE_IMAGES',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (this.$store.state.articleDetails.articleExists) {
          this.failMessage =
            'Beim Laden des Artikelbilds ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    },
    loadCheckboxes() {
      try {
        this.$store.dispatch('GET_CHECKBOXES', this.$route.params.articleNumber)
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Checkboxes found')
        } else {
          if (this.$store.state.articleDetails.articleExists) {
            this.failMessage =
              'Beim Laden der Checkboxen ist ein Fehler aufgetreten'
            this.errorDialog = true
          }
        }
      }
    },
    loadInventoryManagement() {
      try {
        this.$store.dispatch('GET_INVENTORYMANAGEMENT', this.articleNumber)
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Inventory Management found')
        } else {
          if (this.$store.state.articleDetails.articleExists) {
            this.failMessage =
              'Beim Laden der Bestandsführung ist ein Fehler aufgetreten'
            this.errorDialog = true
          }
        }
      }
    }
  },

  watch: {
    '$route.params': 'init',
    tab() {
      const tab = this.tab
      if (this.$route.query.tab != this.tabs[tab]) {
        this.$router.replace({ query: { tab: this.tabs[tab] } })
      }
    }
  },
  destroyed() {
    this.$store.commit('SET_ZERO')
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.ArticleDetailsList {
  list-style-type: none;

  & li {
    padding: 5px;
  }
}
</style>
