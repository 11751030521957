<template>
  <div>
    <v-row class="mt-4 d-flex flex-wrap">
      <v-col cols="12">
        <v-text-field
          v-model="search"
          placeholder="Suche"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-container>
      <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: bold">
              Filter
            </v-expansion-panel-header>

            <v-expansion-panel-content style="margin-top: 30px">
              <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="auto"
                    class="mx-sm-1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Startdatum"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        class="mx-sm-1"
                        clearable
                        outlined
                        solo
                        :value="startDateFormat"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="startdate">
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu1 = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(startdate)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Enddatum"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        class="mx-sm-1"
                        clearable
                        outlined
                        solo
                        :value="endDateFormat"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="enddate">
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu2 = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(enddate)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="document"
                    class="mx-sm-1"
                    :items="alldocument"
                    label="Dokumentart"
                    clearable
                    outlined
                    solo
                    small-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="sm"
                    class="mx-sm-1"
                    :items="allsm"
                    label="Sachmerkmal"
                    clearable
                    outlined
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="customer"
                    class="mx-sm-1"
                    :items="allcustomer"
                    label="Kunde"
                    clearable
                    outlined
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="supplier"
                    class="mx-sm-1"
                    :items="allsupplier"
                    label="Lieferant"
                    clearable
                    outlined
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-autocomplete
                    class="mx-sm-1"
                    :items="allmovement"
                    v-model="movement"
                    return-object
                    item-text="fldDisplayBez"
                    label="Bewegungsart"
                    clearable
                    outlined
                    solo
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="15" md="6" lg="" xl="">
                  <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="ResetFilter()"
                        color="red"
                        class="white--text"
                        style="float: right;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <span>Filter löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="CommissionedOrderedItemsFiltered"
      :loading="loading"
      item-key="docNr"
      :search="search"
      loading-text="Beauftragungen/Bestellungen werden geladen"
    >
      <template v-slot:item.docNr="{ item }">
        <a
          :href="getDocumentLink(item.wf_cid, item.addressId)"
          target="_blank"
          @click.prevent="openDocument(item.wf_cid, item.addressId)"
        >
          {{ item.docNr }}
        </a>
      </template>
      <template v-slot:item.customerName="{ item }">
        <router-link to="#" @click.native="openAddress(item.addressId)">
          {{ item.customerName }}
        </router-link>
      </template>
      <template v-slot:item.supplierName="{ item }">
        <router-link to="#" @click.native="openAddress(item.supplier)">
          {{ item.supplierName }}
        </router-link>
      </template>
      <template v-slot:no-data>
        Keine Beauftragungen/Bestellungen gefunden.
      </template>
      <template v-slot:no-results>
        Keine Beauftragungen/Bestellungen gefunden.
      </template>
      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import moment from 'moment'

export default {
  components: {
    ErrorDialog
  },

  props: {},
  data() {
    return {
      i: null,
      startdate: '',
      enddate: '',
      document: null,
      sm: null,
      customer: null,
      supplier: null,
      movement: null,
      datefiltered: [],
      alldocument: [],
      allsm: [],
      allcustomer: [],
      allsupplier: [],
      allmovement: [],

      menu1: false,
      menu2: false,
      activePicker: null,
      date: null,
      menu: false,
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [
        {
          text: 'Dokument',
          value: 'document',
          sortable: true,
          align: 'start'
        },
        { text: 'Datum', value: 'date', align: 'start' },
        { text: 'Dok.-Nr.', value: 'docNr', align: 'start' },
        { text: 'Position', value: 'position', align: 'start' },
        { text: 'Artikel SM', value: 'articleSM', align: 'start' },
        { text: 'Lokation', value: 'location', align: 'start' },
        { text: 'Kunde', value: 'customerName', align: 'start' },
        { text: 'Lieferant', value: 'supplierName', align: 'start' },
        { text: 'Menge', value: 'quantity', align: 'end' },
        { text: 'Nettopreis', value: 'netprice', align: 'end' },
        { text: 'Eigenanteil', value: 'ownContribution', align: 'end' }
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    startDateFormat() {
      moment.locale()
      return this.startdate ? moment(this.startdate).format('ll') : ''
    },
    endDateFormat() {
      moment.locale()
      return this.enddate ? moment(this.enddate).format('ll') : ''
    },
    CommissionedOrderedItemsFiltered() {
      this.fillFilter()
      return this.CommissionedOrderedItems.filter(item => item.show)
    },

    ...mapState({
      CommissionedOrderedItems: state =>
        state.articleDetails.commissionedOrdered,
      loading: state => state.articleDetails.commissionedOrderedLoader
    })
  },
  watch: {
    '$route.params': 'init',

    menu1(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    startdate() {
      this.DateFilter()
      this.applyFilters()
    },
    enddate() {
      this.DateFilter()
      this.applyFilters()
    },
    document() {
      this.DateFilter()
      this.applyFilters()
    },
    sm() {
      this.DateFilter()
      this.applyFilters()
    },
    customer() {
      this.DateFilter()
      this.applyFilters()
    },
    supplier() {
      this.DateFilter()
      this.applyFilters()
    },
    movement() {
      this.DateFilter()
      this.applyFilters()
    }
  },
  methods: {
    init() {
      this.loadCommissionedOrdered()
      this.fillFilter()
    },
    ResetFilter() {
      this.startdate = null
      this.enddate = null
      this.document = null
      this.sm = null
      this.customer = null
      this.supplier = null
      this.movement = null
    },
    openDocument(wf_cid, addressID) {
      this.$router.push({
        name: 'crmAddressDocumentDetailed',
        params: {
          lfdnr: addressID,
          wf_cid: wf_cid
        }
      })
    },
    getDocumentLink(wfc_id, addressID) {
      return `/crm/addresses/${addressID}/documents/${wfc_id}`
    },
    openAddress(addressID) {
      this.$router.push(`/crm/addresses/${addressID}`)
    },
    DateFilter() {
      this.datefiltered = this.CommissionedOrderedItems
      var beginDateFormat = new Date(this.startdate)
      var endDateFormat = new Date(this.enddate)

      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum')
      }
    },

    fillFilter() {
      if (this.startdate == null || '') {
        this.startdate = ''
      }
      if (this.enddate == null || '') {
        this.enddate = ''
      }
      this.CommissionedOrderedItems.forEach(elem => {
        this.alldocument.push(elem.document)
        this.allsm.push(elem.articleSM)
        this.allcustomer.push(elem.customerName)
        this.allsupplier.push(elem.supplierName)
        if (elem.typeofMovement == 1) {
          this.allmovement.push('Verkauf')
        } else {
          this.allmovement.push('Einkauf')
        }
      })
    },

    // Load Data
    async loadCommissionedOrdered() {
      try {
        await this.$store.dispatch(
          'GET_COMMISSIONED_ORDERED',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Commission Ordered found')
        } else {
          this.failMessage =
            'Beim Laden von Beauftragt/Bestellt ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    },
    //Filter
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    applyFilters() {
      // Setze die show-Eigenschaft für alle Elemente zurück
      this.CommissionedOrderedItems.forEach(item => {
        item.show = true
      })

      // Überprüfe die Filterkriterien und filtere die Daten
      this.CommissionedOrderedItems.forEach(item => {
        var dateConverted = item.date
          .split('.')
          .reverse()
          .join('-')
        var dateFormat = new Date(dateConverted)
        var beginDateFormat = new Date(this.startdate)
        var endDateFormat = new Date(this.enddate)

        if (
          (this.startdate && dateFormat < beginDateFormat) ||
          (this.enddate && dateFormat > endDateFormat) ||
          (this.document && item.document !== this.document) ||
          (this.sm && item.articleSM !== this.sm) ||
          (this.customer && item.customerName !== this.customer) ||
          (this.supplier && item.supplierName !== this.supplier) ||
          (this.movement &&
            ((this.movement === 'Verkauf' && item.typeofMovement === 2) ||
              (this.movement === 'Einkauf' && item.typeofMovement === 1)))
        ) {
          item.show = false
        }
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
