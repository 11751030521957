<template>
  <div>
    <v-row class="mt-3">
      <v-col>
        <v-text-field
          placeholder="Suche"
          v-model="search"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="PurchasePriceItems"
      :loading="loading"
      :search="search"
      loading-text="Einkaufspreise werden geladen"
    >
      <template v-slot:no-data> Keine Einkaufspreise gefunden. </template>

      <template v-slot:no-results> Keine Einkaufspreise gefunden. </template>

      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [
        {
          text: 'Lieferant',
          value: 'supplier',
          sortable: true,
          align: 'start'
        },
        { text: 'gilt ab', value: 'validity', align: 'start' },
        { text: 'Sachmerkmal', value: 'characteristic', align: 'start' },
        { text: 'Einkaufseinheit', value: 'purchasingUnit', align: 'start' },
        { text: 'Faktor', value: 'factor', align: 'end' },
        { text: 'Listenpreis', value: 'listprice', align: 'end' },
        { text: 'Nettopreis', value: 'netprice', align: 'end' },
        { text: 'Währung', value: 'currency', align: 'start' },
        { text: 'Preiseinheit', value: 'priceUnit', align: 'center' },
        { text: 'Rabatt', value: 'discount', align: 'end' },
        { text: 'Rabatt-2', value: 'discount2', align: 'end' },
        { text: 'Einkaufspreis', value: 'purchasePrice', align: 'end' }
      ]
    }
  },
  computed: {
    ...mapState({
      PurchasePriceItems: state => state.articleDetails.purchaseprices,
      loading: state => state.articleDetails.purchasepricesLoader
    })
  },
  methods: {
    init() {
      this.$store.dispatch(
        'GET_PURCHASEPRICES',
        this.$route.params.articleNumber
      )
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    '$route.params': 'init'
  }
}
</script>
