<template>
  <div>
    <v-container v-if="articleExists">
      <v-row class="w-100">
        <v-col cols="12" class="d-flex flex-column align-center">
          <SiteHeaderComponent :header="siteHeader" class="mb-8" />
          <v-tabs grow v-model="tab">
            <v-tab key="AllgemeinTab">Allgemein</v-tab>
            <v-tab key="StatistikTab">Statistik</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" grow>
            <v-tab-item key="AllgemeinTab">
              <ArticleDetails class="mx-auto" />
            </v-tab-item>
            <v-tab-item key="StatistikTab">
              <ArticleStatistics class="mx-auto" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-card class="pa-5 mt-16" style="border-radius: 15px;" elevation="0">
        <v-layout column align-center justify-center fill-height>
          <v-flex xs12 class="text-center mb-4">
            <h3>
              Der gesuchte Artikel konnte nicht gefunden werden
            </h3>
          </v-flex>
          <v-flex xs12>
            <v-img
              class="mx-auto my-auto"
              src="@/assets/svg/undraw_warning_re_eoyh.svg"
              max-width="350px"
              contain
            ></v-img>
          </v-flex>
          <v-flex xs12 class="text-center mt-4">
            <v-btn color="primary" @click="navigateToArticleSearch()">
              <v-icon left>mdi-magnify</v-icon>
              Zurück zur Artikelsuche
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ArticleDetails from '@/components/article/ArticleDetails.vue'
import ArticleStatistics from '@/components/article_statistics/ArticleStatistics.vue'
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ArticleStatistics,
    ArticleDetails,
    SiteHeaderComponent
  },
  data() {
    return {
      tab: 0,
      tabs: ['Allgemein', 'Statistik']
    }
  },
  methods: {
    init() {
      if (this.$route.query.tab == 'Statistik') {
        this.tab = 1
      } else {
        this.$router.replace({ query: { tab: this.tabs[0] } })
      }

      this.loadBasicInformation()
    },
    navigateToArticleSearch() {
      this.$router.push({ name: 'Artikel' })
    },
    loadBasicInformation() {
      try {
        this.$store.dispatch(
          'GET_BASE_INFORMATION',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (this.$store.state.articleDetails.articleExists) {
          this.failMessage =
            'Beim Laden der Basisinformationen ist ein Fehler aufgetreten'
          this.errorDialog = true
          if (err.response.status === 404) {
            console.log('No Base Informations found')
          } else {
            console.error(err)
          }
        }
      }
    }
  },

  computed: {
    ...mapState({
      baseInformation: state => state.articleDetails.baseInformation,
      articleExists: state => state.articleDetails.articleExists
    }),
    siteHeader() {
      return `${this.baseInformation.articleDescription}`
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    '$route.params': 'init',
    tab(newVal) {
      this.$router.replace({ query: { tab: this.tabs[newVal] } })
    }
  }
}
</script>
