<template>
  <div>
    <h5 class="text-h5 text-center">Umsatz pro Artikelsachmerkmal</h5>
    <!--<p class="caption text-center">{{ timePeriod }}</p>-->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 datatable-row-pointer"
        :headers="headers"
        :items="DataPerSM"
        :search="search"
        @click:row="openSaleDataPerAdressForSM"
        :items-per-page="5"
        :item-class="itemRowBackground2"
      >
        <template v-slot:item.revenue="{ item }">
          {{ currencyFormatter(item.revenue) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import currencyFormatService from '@/services/formatters/currencyFormatService'

export default {
  components: {},
  data: () => ({
    search: '',
    characteristic: -1,
    salesPerSM: [],
    headers: [
      {
        text: 'Sachmerkmal',
        align: 'start',
        sortable: false,
        value: 'characteristic'
      },
      { text: 'SM1', value: 'sm1' },
      { text: 'SM2', value: 'sm2' },
      { text: 'SM3', value: 'sm3' },
      { text: 'Menge', value: 'amount', align: 'end' },
      { text: 'Umsatz', value: 'revenue', align: 'end' }
    ]
  }),
  mounted() {},
  computed: {
    ...mapState({
      DataPerSM: state => state.articleStatisticsDataTable.DataPerSM
    })
  },
  methods: {
    openSaleDataPerAdressForSM(value) {
      if (this.characteristic == value.characteristic) {
        this.characteristic = ''
        this.$store.dispatch('showSaleDataPerAdressForSM', false)
      } else {
        this.characteristic = value.characteristic
        this.$store.dispatch('showSaleDataPerAdressForSM', true)
        this.$store.dispatch('SET_ShowDataPerAdressForSM', value.characteristic)
      }
    },

    itemRowBackground2: function(item) {
      if (item.characteristic == this.characteristic) {
        return 'style-1'
      } else {
        return 'style-2'
      }
    },
    currencyFormatter(value) {
      return currencyFormatService.formatEuro(value)
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
* >>> .style-1 {
  background-color: #338b36;
  color: #ffffff;
}
* >>> .style-1:hover {
  background-color: #338b36 !important;
  color: #ffffff;
}
* >>> .style-2 {
  background-color: white;
}
</style>
