<template>
  <div>
    <v-row class="mt-4 d-flex flex-wrap">
      <v-col cols="12">
        <v-text-field
          v-model="search"
          placeholder="Suche"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-container>
      <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: bold">
              Filter
            </v-expansion-panel-header>
            <v-expansion-panel-content style="margin-top: 30px">
              <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
                <v-col cols="15" xs="15" sm="15" md="6" lg="4" xl="3">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="auto"
                    class="mx-sm-1"
                    origin="center center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!--v-model="startdate" in v-text-field-->
                      <v-text-field
                        label="Startdatum"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        class="mx-sm-1"
                        clearable
                        :value="startDateFormat"
                        outlined
                        solo
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="startdate">
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu1 = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(startDate)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="4" xl="3">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="auto"
                    origin="center center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!--v-model="enddate" in v-textfield-->
                      <v-text-field
                        label="Enddatum"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        class="mx-sm-1"
                        clearable
                        :value="endDateFormat"
                        outlined
                        solo
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="enddate">
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu2 = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date2)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="document"
                    class="mx-sm-1"
                    :items="alldocument"
                    label="Dokumentart"
                    clearable
                    outlined
                    origin="center center"
                    solo
                    small-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="sm"
                    class="mx-sm-1"
                    :items="allsm"
                    label="Sachmerkmal"
                    clearable
                    outlined
                    origin="center center"
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="customer"
                    class="mx-sm-1"
                    :items="allcustomer"
                    label="Kunde"
                    clearable
                    outlined
                    origin="center center"
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                    v-model="supplier"
                    class="mx-sm-1"
                    :items="allsupplier"
                    label="Lieferant"
                    clearable
                    outlined
                    origin="center center"
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="4" xl="3">
                  <v-autocomplete
                    class="mx-sm-1"
                    :items="allmovement"
                    v-model="movement"
                    return-object
                    item-text="fldDisplayBez"
                    label="Bewegungsart"
                    clearable
                    outlined
                    origin="center center"
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="15" xs="15" sm="12" md="6" lg="" xl="">
                  <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        sm="12"
                        @click="ResetFilter()"
                        color="red"
                        class="white--text mx-sm-1"
                        style="float: right;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <span>Filter löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="TransportItemsFiltered"
      :loading="loading"
      :search="search"
      loading-text="Artikelbewegungen werden geladen"
    >
      <template v-slot:item.docNr="{ item }">
        <template v-if="item.wfcId">
          <a
            :href="getDocumentLink(item.wfcId, item.kdnr)"
            target="_blank"
            @click.prevent="openDocument(item.wfcId, item.kdnr)"
          >
            {{ item.docNr }}
          </a>
        </template>
        <template v-else>
          {{ item.docNr }}
        </template>
      </template>
      <template v-slot:item.customerName="{ item }">
        <router-link to="#" @click.native="openAddress(item.kdnr)">
          {{ item.customerName }}
        </router-link>
      </template>
      <template v-slot:item.supplierName="{ item }">
        <router-link to="#" @click.native="openAddress(item.supplierId)">
          {{ item.supplierName }}
        </router-link>
      </template>
      <template v-slot:no-data> Keine Artikelbewegungen gefunden. </template>
      <template v-slot:no-results>
        Keine Artikelbewegungen gefunden.
      </template>

      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import moment from 'moment'

export default {
  components: {
    ErrorDialog
  },
  data() {
    return {
      i: null,
      startdate: '',
      enddate: '',
      document: null,
      sm: null,
      customer: null,
      supplier: null,
      movement: null,
      datefiltered: [],
      alldocument: [],
      allsm: [],
      allcustomer: [],
      allsupplier: [],
      allmovement: [],

      menu1: false,
      menu2: false,
      activePicker: null,
      date: null,
      menu: false,
      BeginDate: '',
      EndDate: '',
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },

      headers: [
        {
          text: 'Dokument',
          value: 'document',
          sortable: true,
          align: 'start'
        },
        { text: 'Datum', value: 'date', align: 'start' },
        { text: 'L-Termin', value: 'ldate', align: 'start' },
        { text: 'Dok.-Nr.', value: 'docNr', align: 'start' },
        { text: 'Position', value: 'position', align: 'start' },
        { text: 'Artikel-SM', value: 'articleSM', align: 'start' },
        { text: 'Lokation', value: 'location', align: 'start' },
        { text: 'Kunde', value: 'customerName', align: 'start' },
        { text: 'Lieferant', value: 'supplierName', align: 'start' },
        { text: 'L-Menge', value: 'lQuantity', align: 'end' },
        { text: 'Rg.Menge', value: 'rqQuantity', align: 'end' },
        { text: 'ME', value: 'me', align: 'start' },
        { text: 'Nettopreis', value: 'netprice', align: 'end' },
        { text: 'Eigenanteil', value: 'ownContribution', align: 'end' }
        // { text: 'Lager nach Bewegung', value: 'StockAfterMovement', align: 'end' }
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    TransportItemsFiltered() {
      this.fillFilter()
      return this.TransportItems.filter(item => item.show)
    },
    startDateFormat() {
      moment.locale()
      return this.startdate ? moment(this.startdate).format('ll') : ''
    },
    endDateFormat() {
      moment.locale()
      return this.enddate ? moment(this.enddate).format('ll') : ''
    },

    ...mapState({
      TransportItems: state => state.articleDetails.transport,
      loading: state => state.articleDetails.transportLoader
    })
  },
  watch: {
    '$route.params': 'init',
    menu1(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    startdate() {
      this.DateFilter()
      this.applyFilters()
    },
    enddate() {
      this.DateFilter()
      this.applyFilters()
    },
    document() {
      this.DateFilter()
      this.applyFilters()
    },
    sm() {
      this.DateFilter()
      this.applyFilters()
    },
    customer() {
      this.DateFilter()
      this.applyFilters()
    },
    supplier() {
      this.DateFilter()
      this.applyFilters()
    },
    movement() {
      this.DateFilter()
      this.applyFilters()
    }
  },
  methods: {
    init() {
      this.loadTransport()
      this.fillFilter()
    },
    ResetFilter() {
      this.startdate = null
      this.enddate = null
      this.document = null
      this.sm = null
      this.customer = null
      this.supplier = null
      this.movement = null
    },
    getDocumentLink(wfcId, addressID) {
      return `/crm/addresses/${addressID}/documents/${wfcId}`
    },
    openDocument(wfcId, addressID) {
      this.$router.push(`/crm/addresses/${addressID}/documents/${wfcId}`)
    },
    openAddress(addressID) {
      this.$router.push(`/crm/addresses/${addressID}`)
    },
    DateFilter() {
      var beginDateFormat = new Date(this.startdate)
      var endDateFormat = new Date(this.enddate)

      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum')
      } else {
        for (
          var i = 0, length = this.$store.state.articleDetails.transport.length;
          i < length;
          i++
        ) {
          var object = this.$store.state.articleDetails.transport[i]

          var dateConverted = object.date
            .split('.')
            .reverse()
            .join('-')
          var dateFormat = new Date(dateConverted)

          if (dateFormat < endDateFormat && dateFormat > beginDateFormat) {
            this.$store.commit('SET_DATEFILTER_TRANSPORT', {
              i: i,
              boolean: true
            })
          } else {
            this.$store.commit('SET_DATEFILTER_TRANSPORT', {
              i: i,
              boolean: false
            })
          }
        }
      }
    },
    fillFilter() {
      if (this.startdate == null || '') {
        this.startdate = ''
      }
      if (this.enddate == null || '') {
        this.enddate = ''
      }
      this.TransportItems.forEach(elem => {
        this.alldocument.push(elem.document)
        this.allsm.push(elem.articleSM)
        this.allcustomer.push(elem.customerName)
        this.allsupplier.push(elem.supplierName)
        if (elem.typeofMovement == 1) {
          this.allmovement.push('Verkauf')
        } else {
          this.allmovement.push('Einkauf')
        }
      })
    },

    // Load Data
    async loadTransport() {
      try {
        await this.$store.dispatch(
          'GET_TRANSPORT',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Transports found')
        } else {
          this.failMessage =
            'Beim Laden der Artikelbewegungen ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    applyFilters() {
      // Setze die show-Eigenschaft für alle Elemente zurück
      this.TransportItems.forEach(item => {
        item.show = true
      })

      console.log(this.TransportItems)

      // Überprüfe die Filterkriterien und filtere die Daten
      this.TransportItems.forEach(item => {
        var dateConverted = item.date
          .split('.')
          .reverse()
          .join('-')
        var dateFormat = new Date(dateConverted)
        var beginDateFormat = new Date(this.startdate)
        var endDateFormat = new Date(this.enddate)

        if (
          (this.startdate && dateFormat < beginDateFormat) ||
          (this.enddate && dateFormat > endDateFormat) ||
          (this.document && item.document !== this.document) ||
          (this.sm && item.articleSM !== this.sm) ||
          (this.customer && item.customerName !== this.customer) ||
          (this.supplier && item.supplierName !== this.supplier) ||
          (this.movement &&
            ((this.movement === 'Verkauf' && item.typeofMovement === 2) ||
              (this.movement === 'Einkauf' && item.typeofMovement === 1)))
        ) {
          item.show = false
        }
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
