<template>
  <div>
    <v-row class="mt-4 d-flex flex-wrap">
      <v-col cols="12">
        <v-text-field
          v-model="search"
          placeholder="Suche"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-container>
      <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: bold">
              Filter
            </v-expansion-panel-header>

            <v-expansion-panel-content style="margin-top: 30px">
              <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    v-model="sm"
                    class="mx-sm-1"
                    :items="allsm"
                    label="Sachmerkmal"
                    clearable
                    outlined
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    v-model="warehouse"
                    class="mx-sm-1"
                    :items="allwarehouse"
                    label="Lager"
                    clearable
                    outlined
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    v-model="location"
                    class="mx-sm-1"
                    :items="alllocation"
                    label="Lokation"
                    clearable
                    outlined
                    solo
                    small-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="" xl="">
                  <v-btn
                    @click="ResetFilter()"
                    color="red"
                    class="white--text"
                    style="float: right;"
                  >
                    <v-icon> mdi-close-thick </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="StockItemsFiltered"
      :loading="loading"
      :search="search"
      loading-text="Lagerbestände werden geladen"
    >
      <template v-slot:no-data> Keine Lagerbestände gefunden. </template>
      <template v-slot:no-results> Keine Lagerbestände gefunden. </template>

      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
//import formatService from '@/services/formatters/articleFormatService'
import ErrorDialog from '@/components/core/ErrorDialog.vue'

export default {
  components: {
    ErrorDialog
  },

  data() {
    return {
      sm: null,
      location: null,
      warehouse: null,
      search: '',
      target: 0,
      allsm: [],
      allwarehouse: [],
      alllocation: [],
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      // Will be renewed
      headers: [
        {
          text: 'SM-ID',
          value: 'sm',
          align: 'start',
          sortable: true
        },
        { text: 'SM1', value: 'sm1', align: 'start' },
        { text: 'SM2', value: 'sm2', align: 'start' },
        { text: 'SM3', value: 'sm3', align: 'start' },
        { text: 'Lager', value: 'warehouse' },
        { text: 'Lokation', value: 'location' },
        { text: 'Bestand', value: 'inventory', align: 'end' },
        { text: 'Reserviert', value: 'reserved', align: 'end' },
        { text: 'Bestellt', value: 'ordered', align: 'end' }
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    StockItemsFiltered() {
      this.fillFilter()
      return this.StockItems.filter(
        item =>
          (!this.sm || item.sm === this.sm) &&
          (!this.warehouse || item.warehouse === this.warehouse) &&
          (!this.location || item.location === this.location)
      )
    },
    ...mapState({
      StockItems: state => state.articleDetails.stock,
      loading: state => state.articleDetails.stockLoader
    })
  },

  methods: {
    init() {
      this.loadStock()
      this.fillFilter()
    },
    ResetFilter() {
      this.location = null
      this.warehouse = null
      this.sm = null
    },
    fillFilter() {
      this.StockItems.forEach(elem => {
        this.allsm.push(elem.sm)
        this.allwarehouse.push(elem.warehouse)
        this.alllocation.push(elem.location)
      })
      this.filterDisabled = false
    },
    // Load Data
    async loadStock() {
      try {
        await this.$store.dispatch(
          'GET_STOCK',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Stocks found')
        } else {
          this.failMessage =
            'Beim Laden der Lagerbewegungen ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    '$route.params': 'init'
  }
}
</script>
