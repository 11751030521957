<template>
  <div>
    <v-row class="mt-3">
      <v-col>
        <v-text-field
          placeholder="Suche"
          v-model="search"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="SalePriceItems"
      :loading="loading"
      :search="search"
      loading-text="Verkaufspreise werden geladen"
    >
      <template v-slot:no-data> Keine Verkaufspreise gefunden. </template>
      <template v-slot:no-results> Keine Verkaufspreise gefunden. </template>

      <template v-slot:[`item.bf`]="{ item }">
        <v-simple-checkbox v-model="item.bf" disabled> </v-simple-checkbox>
      </template>

      <template v-slot:[`item.vbp`]="{ item }">
        <v-simple-checkbox v-model="item.vbp" disabled> </v-simple-checkbox>
      </template>

      <template v-slot:[`item.kv`]="{ item }">
        <v-simple-checkbox v-model="item.kv" disabled> </v-simple-checkbox>
      </template>

      <template v-slot:[`item.reha`]="{ item }">
        <v-simple-checkbox v-model="item.reha" disabled> </v-simple-checkbox>
      </template>

      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ErrorDialog from '@/components/core/ErrorDialog.vue'

export default {
  components: {
    ErrorDialog
  },

  data() {
    return {
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [
        {
          text: 'Preisliste',
          value: 'prices',
          sortable: true,
          align: 'start'
        },
        { text: 'gilt ab', value: 'validity', align: 'start' },
        { text: 'Sachmerkmal', value: 'characteristic', align: 'start' },
        { text: 'Verordner', value: 'prescriber', align: 'start' },
        { text: 'Verkaufseinheit', value: 'salesUnit', align: 'start' },
        { text: 'Faktor', value: 'factor', align: 'end' },
        { text: 'Nettopreis', value: 'netprice', align: 'end' },
        { text: 'Bruttopreis', value: 'grossprice', align: 'end' },
        {
          text: 'DB-1%',
          value: 'db1',
          align: 'end' /* Eventually new value name */
        },
        {
          text: 'RA%',
          value: 'ra',
          align: 'end' /* Eventually new value name */
        },
        { text: 'Währung', value: 'currency', align: 'start' },
        { text: 'Preiseinheit', value: 'priceUnit', align: 'center' },
        {
          text: 'BF',
          value: 'bf',
          align: 'center' /* Eventually new value name */
        }, // Checkbox
        { text: 'reha', value: 'reha', align: 'center' } // Checkbox
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    ...mapState({
      SalePriceItems: state => state.articleDetails.saleprices,
      loading: state => state.articleDetails.salepricesLoader
    })
  },
  methods: {
    init() {
      this.loadSalePrices()
    },
    async loadSalePrices() {
      try {
        await this.$store.dispatch(
          'GET_SALEPRICES',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No SalePrices found')
        } else {
          this.failMessage =
            'Beim Laden der Verkaufspreise ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>
