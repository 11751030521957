<template>
  <div>
    <h5 class="text-h5 text-center">Umsatz pro Kunde für Sachmerkmal</h5>
    <!--<p class="caption text-center">{{ timePeriod }}</p>-->
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        class="elevation-1 datatable-row-pointer"
        :headers="headers"
        :items="salesPerAdressForSM"
        :search="search"
        @click:row="openAdresses"
        :item-class="itemRowBackground2"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  address: -1,
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Kunde',
        align: 'start',
        sortable: false,
        value: 'address'
      },
      { text: 'Menge', value: 'amount', align: 'end' },
      { text: 'Umsatz', value: 'revenue', align: 'end' }
    ]
  }),
  mounted() {},
  computed: {
    ...mapState({
      salesPerAdressForSM: state =>
        state.articleStatisticsDataTable.DataPerAdressForSM
    })
  },
  methods: {
    openAdresses(value) {
      this.$router.push(`/crm/addresses/${value.addressId}`)
    },

    opencolor(value) {
      if (this.address == value.address) {
        this.address = ''
      } else {
        this.address = value.address
      }
    },
    itemRowBackground2: function(item) {
      if (item.address == this.address) {
        return 'style-1'
      } else {
        return 'style-2'
      }
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
* >>> .style-1 {
  background-color: #338b36;
}
* >>> .style-1:hover {
  background-color: #338b36 !important;
}
* >>> .style-2 {
  background-color: white;
}
</style>
