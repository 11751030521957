<template>
  <div>
    <apexchart
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import articleFormatService from '@/services/formatters/articleFormatService.js'

export default {
  data() {
    return {
      series: [
        {
          name: 'Umsatz',
          data: this.$store.getters.SaleChartDetailedRevenue
        },
        {
          name: 'Deckungsbeitrag',
          data: this.$store.getters.SaleChartDetailedDB
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
          events: {
            markerClick: (event, chartContext, config) => {
              if (config == config) {
                console.log(config)
              }
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: value => {
            return articleFormatService.formatNumber(value, 2) + '€'
          }
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          type: 'datetime',
          categories: this.$store.getters.SaleChartDetailedDates
        },

        yaxis: {
          title: {
            text: ''
          },
          labels: {
            formatter: function(value) {
              return articleFormatService.formatNumber(value, 2)
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              return articleFormatService.formatNumber(value, 2) + '€'
            }
          }
        }
      }
    }
  }
}
</script>
