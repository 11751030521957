<template>
  <div>
    <v-row class="mt-4 d-flex flex-wrap">
      <v-col xs="12" sm="12" md="12" lg="12" xl="12">
        <v-text-field
          v-model="search"
          placeholder="Suche"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-container>
      <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: bold">
              Filter
            </v-expansion-panel-header>
            <v-expansion-panel-content style="margin-top: 30px">
              <v-row no-gutters class="mt-n7 mb-4 d-flex align-center">
                <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="auto"
                    origin="center center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Startdatum"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        color="primary"
                        v-on="on"
                        class="mx-sm-1"
                        clearable
                        solo
                        outlined
                        :value="startDateFormat"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="startdate">
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu1 = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(date1)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="400px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Enddatum"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        color="primary"
                        class="mx-sm-1"
                        v-on="on"
                        outlined
                        solo
                        :value="endDateFormat"
                        clearable
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="enddate">
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary" @click="menu2 = false"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date2)"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12 " lg="" xl="">
                  <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="ResetFilter()"
                        color="red"
                        class="white--text"
                        style="float: right;"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <span>Filter löschen</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="OpenOffersItemsFiltered"
      :loading="loading"
      item-key="docNr"
      :search="search"
      loading-text="offene Angebote werden geladen"
    >
      <template v-slot:item.docNr="{ item }">
        <router-link
          to="#"
          @click.native="openDocument(item.wf_cid, item.addressId)"
        >
          {{ item.docNr }}
        </router-link>
      </template>
      <template v-slot:no-data>
        Keine offenen Angebote gefunden
      </template>
      <template v-slot:no-results>
        Keine offenen Angebote gefunden
      </template>
      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import moment from 'moment'

export default {
  components: {
    ErrorDialog
  },

  props: {},
  data() {
    return {
      menu1: false,
      menu2: false,
      activePicker: null,
      date: null,
      menu: false,
      startdate: '',
      enddate: '',
      BeginDate: '',
      EndDate: '',
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [
        {
          text: 'Dokument',
          value: 'document',
          sortable: true,
          align: 'start'
        },
        { text: 'Datum', value: 'date', align: 'start' },
        { text: 'Dok.-Nr.', value: 'docNr', align: 'start' },
        { text: 'Position', value: 'position', align: 'start' },
        { text: 'Artikel SM', value: 'articleSM', align: 'start' },
        { text: 'Lokation', value: 'location', align: 'start' },
        { text: 'Menge', value: 'quantity', align: 'end' },
        { text: 'Nettopreis', value: 'netprice', align: 'end' },
        { text: 'Eigenanteil', value: 'ownContribution', align: 'end' }
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    ...mapGetters(['getOpenOffers']),
    OpenOffersItemsFiltered() {
      console.warn(this.getOpenOffers)
      this.fillFilter()
      return this.OpenOffersItems.filter(item => item.show)
    },
    ...mapState({
      OpenOffersItems: state => state.articleDetails.openOffers,
      loading: state => state.articleDetails.openOffersLoader
    }),
    startDateFormat() {
      moment.locale()
      return this.startdate ? moment(this.startdate).format('ll') : ''
    },
    endDateFormat() {
      moment.locale()
      return this.enddate ? moment(this.enddate).format('ll') : ''
    }
  },
  watch: {
    '$route.params': 'init',
    menu1(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    startdate() {
      this.DateFilter()
      this.applyFilters()
    },
    enddate() {
      this.DateFilter()
      this.applyFilters()
    }
  },
  methods: {
    init() {
      this.loadOpenOffers()
      this.fillFilter()
    },
    openDocument(wfc_id, addressID) {
      this.$router.push(`/crm/addresses/${addressID}/documents/${wfc_id}`)
    },
    ResetFilter() {
      this.startdate = null
      this.enddate = null
    },
    DateFilter() {
      var beginDateFormat = new Date(this.startdate)
      var endDateFormat = new Date(this.enddate)

      if (endDateFormat < beginDateFormat) {
        this.$toast.warning('Das Enddatum ist vor dem Beginndatum')
      }
    },
    fillFilter() {
      if (this.startdate == null || '') {
        this.startdate = ''
      }
      if (this.enddate == null || '') {
        this.enddate = ''
      }
    },

    // Load Data
    async loadOpenOffers() {
      try {
        await this.$store.dispatch(
          'GET_OPEN_OFFERS',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Offers found')
        } else {
          this.failMessage =
            'Beim Laden der offenen Angebote ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    },
    applyFilters() {
      // Setze die show-Eigenschaft für alle Elemente zurück
      this.OpenOffersItems.forEach(item => {
        item.show = true
      })

      // Überprüfe die Filterkriterien und filtere die Daten
      this.OpenOffersItems.forEach(item => {
        var dateConverted = item.date
          .split('.')
          .reverse()
          .join('-')
        var dateFormat = new Date(dateConverted)
        var beginDateFormat = new Date(this.startdate)
        var endDateFormat = new Date(this.enddate)

        if (
          (this.startdate && dateFormat < beginDateFormat) ||
          (this.enddate && dateFormat > endDateFormat)
        ) {
          item.show = false
        }
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
