<template>
  <div>
    <v-row class="mt-3">
      <v-col>
        <v-text-field
          placeholder="Suche"
          v-model="search"
          outlined
          width="50"
          clearable
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="black--text"
      :headers="headers"
      :items="characteristicsItems"
      :loading="loading"
      :search="search"
      loading-text="Sachmerkmale werden geladen"
    >
      <template v-slot:no-data> Keine Sachmerkmale gefunden. </template>
      <template v-slot:no-results> Keine Sachmerkmale gefunden. </template>
      <template v-slot:[`footer.prepend`]>
        <v-btn
          class="ml-2 mb-2"
          small
          depressed
          @click="$vuetify.goTo(target, scrollOptions)"
        >
          Nach oben
          <v-icon> mdi-arrow-up-thin </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ErrorDialog
      :failMessage="failMessage"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ErrorDialog from '@/components/core/ErrorDialog.vue'

export default {
  components: {
    ErrorDialog
  },
  data() {
    return {
      search: '',
      target: 0,
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      },
      headers: [
        {
          text: 'SM-ID',
          value: 'lfdnr',
          sortable: true,
          align: 'start'
        },
        { text: 'SM1', value: 'sm1', align: 'start' },
        { text: 'SM2', value: 'sm2', align: 'start' },
        { text: 'SM3', value: 'sm3', align: 'start' },
        { text: 'EAN-Code', value: 'eanCode', align: 'start' },
        { text: 'Mind.-Bestand', value: 'minStock', align: 'end' },
        { text: 'VPE', value: 'vpe', align: 'end' },
        { text: 'DEP', value: 'dep', align: 'end' },
        { text: 'D-BZK', value: 'dbzk', align: 'end' }
      ],
      failMessage: '',
      errorDialog: false
    }
  },
  computed: {
    ...mapState({
      characteristicsItems: state => state.articleDetails.characteristics,
      loading: state => state.articleDetails.characteristicsLoader
    })
  },
  methods: {
    init() {
      this.loadCharacteristics()
    },
    async loadCharacteristics() {
      try {
        await this.$store.dispatch(
          'GET_CHARACTERISTICS',
          this.$route.params.articleNumber
        )
      } catch (err) {
        if (err.request.status === 404) {
          console.log('No Characteristics found')
        } else {
          this.failMessage =
            'Beim Laden der Sachmerkmale ist ein Fehler aufgetreten'
          this.errorDialog = true
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    '$route.params': 'init'
  }
}
</script>
